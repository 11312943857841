console.log('Ready javascript');

// Mobile > all touchstar DOM perfect Iphone
document.addEventListener("touchstart", function(){}, true);

// Top Scroll button element
$('body').materialScrollTop();

$("[data-fancybox]").fancybox({
  iframe : {
    css : {
      width : '800px',
      height: '80%'
    }
  }
});

// anchor link
 // Add class menu reference archos url load page
 if (window.location.hash!=""){
  var menu__top = document.getElementById("menu-top");
  menu__top.className = "";
  var archos = window.location.hash.substr(1);
  menu__top.classList.add(archos);
}

// Add smooth scrolling to all links
$("a").on('click', function(event) {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {



      $('.header__menu').removeClass('is-active');

      
      // Prevent default anchor click behavior
      // event.preventDefault();

      // Store hash
      var hash = this.hash;

      var menu__top = document.getElementById("menu-top");
      menu__top.className = "";

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area

      var headerHeight = document.getElementsByTagName("header");
      if (headerHeight[0].classList != "fixed"){
        
        var topPage = $(hash).offset().top;
      } else {
        var topPage = $(hash).offset().top;
      //console.log(topPage);
    }

    $('html, body').animate({
      scrollTop: topPage
    }, 800, function(){

        //console.log($(hash).offset().top);
        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;

        var archos = window.location.hash.substr(1);
        menu__top.classList.add(archos);

        var headerHeight = document.getElementsByTagName("header");

      });
    } // End if


  });

// Resize window for web
window.addEventListener("scroll", resizeFunction);
window.addEventListener("resize", resizeFunction);
window.addEventListener("load", resizeFunction);

function resizeFunction() {
  var bodyHeight = document.documentElement.clientHeight;
  //console.log(window.pageYOffset);
}


window.addEventListener("scroll",recorrido);
function recorrido(){
      // sectors = ["empresa","distribucio","elaboracio-propia","hosteleria","contacte"];
      sectors = ["home","empresa","fabrica","productes","distribucio","serveis","productes-hosteleria","contacte"];

      pixelTop = [];for (var i = 0; i < sectors.length; i++) {
        d = document.getElementById(sectors[i]);
        pixelTop[i] = d.offsetTop - window.pageYOffset-document.getElementById("header").offsetHeight;

        if (pixelTop[i]<= document.getElementById("header").offsetHeight) {
      //console.log(pixelTop[i]);
      var menu__top = document.getElementById("menu-top");
      menu__top.className = "";
      menu__top.classList.add(sectors[i]);
    }
  }
}

$('#js-btn-mobile').click(function(){
  $('.header__menu').toggleClass('is-active');
});

// https://stackoverflow.com/questions/51554452/flickity-carousel-autoplay-stops-after-user-interaction
if (document.querySelector('.carousel')){
  const Flickity = require('flickity');
  var elem = document.querySelector('.carousel');
  var flkty = new Flickity( elem, {
    prevNextButtons : false,
    continuousAutoPlay : true,
    autoPlaySkipCell : true,
    wrapAround : true,
    autoPlay : 4000 ,
    pauseAutoPlayOnHover : false,
    imagesLoaded : true,
    dragThreshold : 0,
    selectedAttraction : "0.01",
    friction  : "0.18",
    fade  : true
  });

  flkty.on('pointerUp', function (event, pointer) {
    flkty.player.play();
  });

  flkty.on('uiChange', function() {
    flkty.player.play();
  });
}




